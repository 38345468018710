import { fromJS } from 'immutable';

function isICO(values) {
  const digits = values.toString().split('');
  let count = 0;
  for (let i = 0; i < 7; i += 1) {
    count += digits[i] * (8 - i);
  }
  count %= 11;
  let c;
  if (count === 0) {
    c = 1;
  } else if (count === 1) {
    c = 0;
  } else {
    c = 11 - count;
  }
  return digits[7] === c.toString();
}

const validate = (data) => {
  const values = fromJS(data);
  const errors = {};
  if (!values.get('name')) {
    errors.name = 'validate.required';
  }
  if (!values.get('company_name')) {
    errors.company_name = 'validate.required';
  }
  if (!values.get('eshop_email')) {
    errors.eshop_email = 'validate.required';
  } else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
      values.get('eshop_email'),
    )
  ) {
    errors.eshop_email = 'validate.email';
  }
  if (!values.get('address')) {
    errors.address = 'validate.required';
  }
  // if (!values.get('country_id')) {
  //   errors.country_id = 'validate.required';
  // }
  if (values.get('tax_number') && !/^CZ[0-9]{8,10}$/i.test(values.get('tax_number'))) {
    errors.tax_number = 'validate.dic';
  }
  if (!values.get('registration_number')) {
    errors.registration_number = 'validate.required';
  } else if (
    !/^[0-9]{8}$/i.test(values.get('registration_number'))
    && values.get('registration_number')
  ) {
    errors.registration_number = 'validate.ico';
  } else if (!isICO(values.get('registration_number'))) {
    errors.registration_number = 'validate.ico';
  }
  if (!values.get('bank_account')) {
    errors.bank_account = 'validate.required';
  } else if (
    !/^[0-9-]+\/[0-9]{4}$/i.test(values.get('bank_account') && values.get('bank_account'))
  ) {
    errors.bank_account = 'validate.bank_account';
  }
  if (!values.get('site_url')) {
    errors.site_url = 'validate.required';
  } else if (
    !/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i.test(
      values.get('site_url'),
    )
    && values.get('site_url')
  ) {
    errors.site_url = 'validate.url';
  }
  // if (!values.get('success_callback_url')) {
  //   errors.success_callback_url = 'validate.required';
  // } else if (
  //   !/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i.test(
  //     values.get('success_callback_url'),
  //   )
  //   && values.get('success_callback_url')
  // ) {
  //   errors.success_callback_url = 'validate.url';
  // }
  if (values.get('image_logo') && !/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i.test(
    values.get('image_logo'),
  )) {
    errors.image_logo = 'validate.url';
  }
  return errors;
};

export default validate;
