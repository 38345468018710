const defaultTheme = {
  width: '1100px',
  fontSize: '15px',
  colors: {
    default: '#313131',
    background: '#f1f3f6',

    white: '#ffffff',
    red: '#dc3545',
    orange: '#fd7e14',
    yellow: '#ffc107',
    green: '#608c23',

    primary: '#7e3f91',
    primaryDarker: '#6b357b',
    secondary: '#5ea54a',
    tertiary: '#53b9d6',
  },
};

export default defaultTheme;
