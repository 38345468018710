import React from 'react';
import { injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';
import pt from 'prop-types';
import { NavLink } from 'react-router-dom';

import StyledComponent from './styles';
import {
  Layout, H1, H2, Container, Row, Column, P,
} from '../../components';

const Homepage = ({ intl, isLoading, isLoggedIn, isRegistered }) => (
  <Layout>
    <StyledComponent>
      {
        !isLoading ? (
          <Container>
            <H1>{intl.formatMessage({ id: 'app.name' })}</H1>
            {isLoggedIn && isRegistered && (
            <center>
              <p>Pro nastavení doplňku pokračujte do <NavLink to="/dashboard" activeClassName="active">{intl.formatMessage({ id: 'nav.admin' })}</NavLink></p>
            </center>
            )}
            <Row>
              <Column>
                <H2>{intl.formatMessage({ id: 'homepage.col1.header' })}</H2>
                <FormattedHTMLMessage id="homepage.col1.body" />
              </Column>
              <Column>
                <H2>{intl.formatMessage({ id: 'homepage.col2.header' })}</H2>
                <FormattedHTMLMessage id="homepage.col2.body" />
              </Column>
            </Row>
            {/* // <Row>
                  //   <Column>
                  //     <H2>{intl.formatMessage({ id: 'homepage.col3.header' })}</H2>
                  //     <FormattedHTMLMessage id="homepage.col3.body" />
                  //   </Column>
                  //   <Column>
                  //     <H2>{intl.formatMessage({ id: 'homepage.col4.header' })}</H2>
                  //     <FormattedHTMLMessage id="homepage.col4.body" />
                  //   </Column>
                  // </Row> */}
          </Container>
        ) : null
      }
    </StyledComponent>
  </Layout>
);

const mapStateToProps = state => ({
  isLoading: state.user.get('loading'),
  isLoggedIn: state.user.get('tokenValidity'),
  isRegistered: state.eshop.get('isRegistered'),
});

const mapDispatchToProps = dispatch => ({});

Homepage.defaultProps = {
  isLoading: true,
  isLoggedIn: false,
  isRegistered: false,
};

Homepage.propTypes = {
  intl: intlShape.isRequired,
  isLoading: pt.bool,
  isLoggedIn: pt.bool,
  isRegistered: pt.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Homepage));
