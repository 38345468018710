import { fromJS } from 'immutable';

const validate = (data) => {
  const values = fromJS(data);
  const errors = {};
  if (!values.get('title')) {
    errors.title = 'validate.required';
  }
  return errors;
};

export default validate;
