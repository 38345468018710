import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { intlReducer } from 'react-intl-redux';
import { reducer as formReducer } from 'redux-form';
import userReducer from './user/reducer';
import eshopReducer from './eshop/reducer';
import apiKeyReducer from './apiKey/reducer';
import wishReducer from './wish/reducer';
import widgetReducer from './widget/reducer';

export default history => combineReducers({
  router: connectRouter(history),
  intl: intlReducer,
  apiKey: apiKeyReducer,
  eshop: eshopReducer,
  user: userReducer,
  form: formReducer,
  wish: wishReducer,
  widget: widgetReducer,
});
