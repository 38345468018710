import { fromJS } from 'immutable';
import {
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  LOG_OUT_REQUEST,
  LOG_OUT_SUCCESS,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  LOG_IN_REQUEST,
  LOG_IN_FAIL,
} from './constants';

const initialState = fromJS({
  tokenValidity: false,
  error: null,
  loading: true,
});

export const actionHandlers = {
  [LOG_IN_REQUEST](state) {
    return state.merge({
      loading: true,
    });
  },
  [LOG_IN_FAIL](state, { error }) {
    return state.merge({
      tokenValidity: false,
      loading: false,
      error,
    });
  },
  [LOG_OUT_REQUEST](state) {
    return state.merge({
      loading: true,
    });
  },
  [LOG_OUT_SUCCESS](state) {
    return state.merge({
      tokenValidity: false,
      loading: false,
    });
  },
  [GET_PROFILE_SUCCESS](state) {
    return state.merge({
      tokenValidity: true,
      loading: false,
    });
  },
  [GET_PROFILE_FAIL](state, { error }) {
    return state.merge({
      tokenValidity: false,
      error,
      loading: false,
    });
  },
  [CHANGE_PASSWORD_REQUEST](state) {
    return state.merge({
      loading: true,
    });
  },
  [CHANGE_PASSWORD_SUCCESS](state) {
    return state.merge({
      loading: false,
      error: null,
    });
  },
  [CHANGE_PASSWORD_FAIL](state, { error }) {
    return state.merge({
      loading: false,
      error,
    });
  },
};

function userReducer(state = initialState, action) {
  const { type } = action;
  if (type in actionHandlers) {
    return actionHandlers[type](state, action);
  }
  return state;
}

export default userReducer;
