import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';

const StyledHeader = styled.h2`
  margin-top: 3rem;
  font-size:1.7rem;

`;

const H2 = ({ children }) => <StyledHeader>{children}</StyledHeader>;

H2.propTypes = {
  children: pt.string.isRequired,
};

export default H2;
