import React from 'react';
import pt from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { injectIntl, intlShape } from 'react-intl';

import StyledComponent from './styles';
import validate from './validate';
import FormField from '../FormField';
import Button from '../../Atoms/Button';

const ChangePasswordForm = (props) => {
  const {
    intl, handleSubmit, submitting,
  } = props;
  return (
    <StyledComponent>
      <form onSubmit={handleSubmit}>
        <Field
          name="password"
          type="password"
          component={FormField}
          label={intl.formatMessage({ id: 'changePassword.password.title' })}
        />
        <Field
          name="passwordRepeat"
          type="password"
          component={FormField}
          label={intl.formatMessage({ id: 'changePassword.passwordRepeat.title' })}
        />
        <Button type="submit" disabled={submitting}>
          {intl.formatMessage({ id: 'common.submit' })}
        </Button>
      </form>
    </StyledComponent>
  );
};

ChangePasswordForm.defaultProps = {};

ChangePasswordForm.propTypes = {
  intl: intlShape.isRequired,
  handleSubmit: pt.func.isRequired,
  submitting: pt.bool.isRequired,
};

export default reduxForm({
  form: 'ChangePasswordForm',
  validate,
})(injectIntl(ChangePasswordForm));
