import { fromJS } from 'immutable';
import {
  REGISTER_ESHOP_SUCCESS,
  REGISTER_ESHOP_FAIL,
  REGISTER_ESHOP_REQUEST,
  UPDATE_ESHOP_REQUEST,
  UPDATE_ESHOP_FAIL,
  UPDATE_ESHOP_SUCCESS,
  LIST_ESHOPS_REQUEST,
  LIST_ESHOPS_SUCCESS,
  LIST_ESHOPS_FAIL,
  SET_ACTIVE_ESHOP,
} from './constants';
import { LOG_OUT_SUCCESS } from '../user/constants';
import { convertArrayToObject } from '../../utils/utilities';

const initialState = fromJS({
  error: null,
  eshops: null,
  loading: false,
  activeEshopId: null,
});

export const actionHandlers = {
  [SET_ACTIVE_ESHOP](state, { eshopId }) {
    return state.merge({
      activeEshopId: eshopId,
    });
  },
  [LIST_ESHOPS_REQUEST](state) {
    return state.merge({
      eshops: [],
      loading: true,
      error: null,
    });
  },
  [LIST_ESHOPS_SUCCESS](state, { eshops }) {
    return state.merge({
      eshops: convertArrayToObject(eshops, 'id'),
      activeEshopId: eshops.length ? eshops[0].id : null,
      loading: false,
    });
  },
  [LIST_ESHOPS_FAIL](state, { error }) {
    return state.merge({
      error,
      loading: false,
    });
  },
  [UPDATE_ESHOP_REQUEST](state) {
    return state.merge({
      loading: true,
      error: null,
    });
  },
  [UPDATE_ESHOP_SUCCESS](state) {
    return state.merge({
      error: null,
      loading: false,
    });
  },
  [UPDATE_ESHOP_FAIL](state, { error }) {
    return state.merge({
      error,
      loading: false,
    });
  },
  [REGISTER_ESHOP_REQUEST](state) {
    return state.merge({
      loading: true,
      error: null,
    });
  },
  [LOG_OUT_SUCCESS](state) {
    return state.merge({
      eshops: [],
    });
  },
  [REGISTER_ESHOP_SUCCESS](state) {
    return state.merge({
      loading: false,
    });
  },
  [REGISTER_ESHOP_FAIL](state, { error }) {
    return state.merge({
      error,
      loading: false,
    });
  },
};

function userReducer(state = initialState, action) {
  const { type } = action;
  if (type in actionHandlers) {
    return actionHandlers[type](state, action);
  }
  return state;
}

export default userReducer;
