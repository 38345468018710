import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';

const StyledHeader = styled.h3`
`;

const H3 = ({ children }) => (
  <StyledHeader>
    {children}
  </StyledHeader>
);

H3.propTypes = {
  children: pt.string.isRequired,
};

export default H3;
