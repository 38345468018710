import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';

import 'normalize.css';
import './styles/style.css';

import Wrapper from './Wrapper';
import * as serviceWorker from './serviceWorker';
import { store, history } from './store';

// eslint-disable-next-line react/jsx-filename-extension
ReactDOM.render(<Wrapper store={store} history={history} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
