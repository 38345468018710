export const REGISTER_ESHOP_REQUEST = 'REGISTER_ESHOP_REQUEST';
export const REGISTER_ESHOP_SUCCESS = 'REGISTER_ESHOP_SUCCESS';
export const REGISTER_ESHOP_FAIL = 'REGISTER_ESHOP_FAIL';

export const UPDATE_ESHOP_REQUEST = 'UPDATE_ESHOP_REQUEST';
export const UPDATE_ESHOP_SUCCESS = 'UPDATE_ESHOP_SUCCESS';
export const UPDATE_ESHOP_FAIL = 'UPDATE_ESHOP_FAIL';

export const LIST_ESHOPS_REQUEST = 'LIST_ESHOPS_REQUEST';
export const LIST_ESHOPS_SUCCESS = 'LIST_ESHOPS_SUCCESS';
export const LIST_ESHOPS_FAIL = 'LIST_ESHOPS_FAIL';

export const SET_ACTIVE_ESHOP = 'SET_ACTIVE_ESHOP';
