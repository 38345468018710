import React, { Component } from 'react';
import pt from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import smoothscroll from 'smoothscroll-polyfill';

import StyledComponent from './styles';
import {
  Layout, H1, H2, Container, Alert, WishView, Loading, Row, Column,
} from '../../components';
import { AddonForm } from '../../components/forms';
import { updateWidget, getWidget, getWidgetPositions } from '../../reducers/widget/actions';
import listWishes from '../../reducers/wish/actions';
import Button from '../../components/Atoms/Button';
import config from '../../config';

// function getOffset(el) {
//   const rect = el.getBoundingClientRect();
//   return {
//     left: rect.left + window.scrollX,
//     top: rect.top + window.scrollY,
//   };
// }

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmited: false,
      previewScript: null,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    smoothscroll.polyfill();
  }

  componentDidMount() {
    const {
      wishes,
      handleWish,
      onGetWidget,
      widgetData,
      widgetPositions,
      onGetWidgetPositions,
      eshopData,
    } = this.props;
    if (wishes === null) {
      handleWish();
    }
    if (widgetPositions === null && eshopData && eshopData.isShoptet) {
      onGetWidgetPositions();
    }
    if (widgetData === null) {
      onGetWidget();
    }
  }

  componentDidUpdate(prevProps) {
    const { widgetData, eshopData, activeEshopId, onGetWidget, onGetWidgetPositions } = this.props;
    const { previewScript } = this.state;
    if (eshopData) {
      if (activeEshopId !== prevProps.activeEshopId) {
        this.setState({ formSubmited: false });
        onGetWidget();
        if (eshopData.isShoptet) {
          onGetWidgetPositions();
        }
      }
      if (widgetData && eshopData.isShoptet && prevProps.widgetData !== widgetData) {
        if (previewScript === null) {
          this.createWidgetPreview();
        } else {
          this.updateWidgetPreview();
        }
      }
    }
  }

  getWidgetBaseUrl = eshopId => `https://widget.${config.appDomain}/default/${eshopId}`;

  getWidgetScript() {
    const { widgetData } = this.props;
    const url = this.getWidgetBaseUrl(widgetData.eshop_id);
    return `<script defer id="wowee-script" type="text/javascript" src="${url}">Javascriprt is needed</script>`;
  }

  updateWidgetPreview() {
    const { previewScript } = this.state;
    const preview = document.getElementById('widget-preview');

    if (preview) {
      preview.innerHTML = '';
    }

    previewScript.parentNode.removeChild(previewScript);
    this.createWidgetPreview();
  }

  createWidgetPreview() {
    const { widgetData } = this.props;
    const script = document.createElement('script');
    const src = `https://widget.${config.appDomain}/shoptet?eshopId=${widgetData.eshop_id}&price=600&curr=CZK`;

    script.src = src;
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);
    this.setState({
      previewScript: script,
    });
  }

  handleFormSubmit(values) {
    const { onUpdateWidget } = this.props;
    // const preview = document.getElementById('widget-preview');
    // const { top } = getOffset(preview);
    onUpdateWidget(values);
    // window.scroll({ top: top - 70, left: 0, behavior: 'smooth' });
    this.setState({
      formSubmited: true,
    });
  }

  render() {
    const {
      intl,
      handleWish,
      wishes,
      wishCount,
      wishLimit,
      widgetSubmitErr,
      widgetData,
      widgetPositions,
      onWidgetCreate,
      onWidgetEnable,
      eshopData,
    } = this.props;
    const { formSubmited } = this.state;

    if (!eshopData) {
      return null;
    }

    const { isShoptet } = eshopData;

    return (
      <Layout>
        <StyledComponent>
          <Container>
            <H1>{intl.formatMessage({ id: 'dashboard.title' })}</H1>
            <H2>{intl.formatMessage({ id: 'dashboard.wishes' })}</H2>
            <WishView data={wishes} />
            {
              wishes && wishes.length ? (
                <ReactPaginate
                  previousLabel={intl.formatMessage({ id: 'common.prevlabel' })}
                  nextLabel={intl.formatMessage({ id: 'common.nextlabel' })}
                  breakLabel="..."
                  breakClassName="break-me"
                  pageCount={Math.ceil(wishCount / wishLimit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handleWish}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                />
              ) : null
            }
            {
              isShoptet && widgetData && widgetData.is_enabled ? (
                <Row>
                  <Column>
                    <H2>{intl.formatMessage({ id: 'dashboard.settings.widgetPreview' })}</H2>
                    <div id="widget-preview" />
                  </Column>
                </Row>
              ) : null
            }
            {
              widgetData ? (
                <Row>
                  <Column>
                    <H2>{intl.formatMessage({ id: 'dashboard.settings' })}</H2>
                    {
                      widgetData.is_enabled ? (
                        <div>
                          <Button type="link" onClick={() => onWidgetEnable(widgetData, false)}>
                            {intl.formatMessage({ id: 'dashboard.activation.disable' })}
                          </Button>
                          {widgetSubmitErr ? (
                            <Alert type="error">{intl.formatMessage({ id: 'common.error' })}</Alert>
                          ) : null}
                          {
                            formSubmited && !widgetSubmitErr ? (
                              <Alert type="success">
                                {intl.formatMessage({ id: 'dashboard.settings.success' })}
                              </Alert>
                            ) : null
                          }
                          {
                            !isShoptet && widgetData ? (
                              <AddonForm
                                isShoptet={false}
                                onSubmit={this.handleFormSubmit}
                                initialValues={widgetData}
                              />
                            ) : null
                          }
                          {
                            !isShoptet && !widgetData ? (
                              <Button type="link" onClick={onWidgetCreate}>
                                {intl.formatMessage({ id: 'dashboard.settings.widgetCreate' })}
                              </Button>
                            ) : null
                          }
                          {
                            isShoptet && widgetData && widgetPositions ? (
                              <AddonForm
                                isShoptet
                                onSubmit={this.handleFormSubmit}
                                initialValues={widgetData}
                                positionsDesktop={
                                  widgetPositions.desktop.map(({ id, name }) => ({
                                    id,
                                    name,
                                    value: id,
                                  }))
                                }
                                positionsMobile={
                                  widgetPositions.mobile.map(({ id, name }) => ({
                                    id,
                                    name,
                                    value: id,
                                  }))
                                }
                              />
                            ) : null
                          }
                        </div>
                      )
                        : (
                          <Button type="link" onClick={() => onWidgetEnable(widgetData, true)}>
                            {intl.formatMessage({ id: 'dashboard.activation.enable' })}
                          </Button>
                        )
                    }
                  </Column>
                </Row>
              ) : null
            }
            {
              !isShoptet && widgetData ? (
                <Row>
                  <Column>
                    <H2>{intl.formatMessage({ id: 'dashboard.settings.widgetScript' })}</H2>
                    <textarea className="widget-script-preview" readOnly="readonly" value={this.getWidgetScript()} />
                    <p>Pro správnou funkčnost nastavte tyto GET query parametry</p>
                    <ul>
                      <li>item (ID produktu)</li>
                      <li>price (cena produktu) </li>
                      <li>curr (měna v ISO formátu, např. czk)</li>
                    </ul>
                    <p>
                      <b>Příklad správné URL: </b>
                      {this.getWidgetBaseUrl(widgetData.eshop_id)}
                      ?item=1234&price=1999&curr=czk
                    </p>
                  </Column>
                </Row>
              ) : null
            }
          </Container>
        </StyledComponent>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  widgetSubmitErr: state.widget.get('error'),
  widgetData: state.widget.get('data'),
  eshops: state.eshop.get('eshops'),
  eshopData: state.eshop.get('eshops') ? state.eshop.get('eshops')[state.eshop.get('activeEshopId')] : null,
  activeEshopId: state.eshop.get('activeEshopId'),
  widgetPositions: state.widget.get('positions'),
  wishes: state.wish.get('wishes'),
  wishCount: state.wish.get('count'),
  wishLimit: state.wish.get('limit'),
});

const mapDispatchToProps = dispatch => ({
  onGetWidget: () => {
    dispatch(getWidget());
  },
  onWidgetEnable: (data, status) => {
    const updatedData = Object.assign(data, {
      is_enabled: status,
    });
    dispatch(updateWidget(updatedData));
  },
  onWidgetCreate: () => {
    dispatch(updateWidget(null));
  },
  onGetWidgetPositions: () => {
    dispatch(getWidgetPositions());
  },
  onUpdateWidget: (values) => {
    dispatch(updateWidget(values));
  },
  handleWish: (data) => {
    const limit = 10;
    if (data) {
      const { selected } = data;
      const offset = Math.ceil(selected * limit);
      dispatch(listWishes(limit, offset));
    } else {
      const offset = 0;
      dispatch(listWishes(limit, offset));
    }
  },
});

Dashboard.defaultProps = {
  widgetSubmitErr: null,
  wishes: [],
  wishCount: 0,
  wishLimit: 10,
  isShoptet: false,
  widgetData: null,
  widgetPositions: null,
};

Dashboard.propTypes = {
  intl: intlShape.isRequired,
  widgetSubmitErr: pt.string,
  onUpdateWidget: pt.func.isRequired,
  onGetWidget: pt.func.isRequired,
  onGetWidgetPositions: pt.func.isRequired,
  handleWish: pt.func.isRequired,
  wishes: pt.instanceOf(Array),
  wishCount: pt.number,
  wishLimit: pt.number,
  widgetData: pt.object,
  widgetPositions: pt.object,
  onWidgetEnable: pt.func.isRequired,
  isShoptet: pt.bool,
  onWidgetCreate: pt.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Dashboard));
