import { put, takeLatest, select } from 'redux-saga/effects';

import {
  LIST_API_KEY_REQUEST,
  LIST_API_KEY_SUCCESS,
  CREATE_API_KEY_REQUEST,
  CREATE_API_KEY_SUCCESS,
  API_KEY_FAIL,
  DEACTIVATE_API_KEY_REQUEST,
  DEACTIVATE_API_KEY_SUCCESS,
} from '../reducers/apiKey/constants';
import { callApi } from './api';

export const getActiveEshopId = state => state.eshop.get('activeEshopId');

export function* listApiKey() {
  try {
    const eshopId = yield select(getActiveEshopId);
    const data = yield callApi(`/api-key?eshopId=${eshopId}`);
    yield put({
      type: LIST_API_KEY_SUCCESS,
      apiKeyData: data.apiKeys,
    });
  } catch (err) {
    yield put({
      type: API_KEY_FAIL,
      error: err.message,
    });
  }
}

export function* createApiKey({ values }) {
  try {
    const data = yield callApi('/api-key', 'PUT', values);
    yield put({
      type: CREATE_API_KEY_SUCCESS,
      apiKeyData: data,
    });
  } catch (err) {
    yield put({
      type: API_KEY_FAIL,
      error: err.message,
    });
  }
}

export function* deactivateApiKey({ id }) {
  try {
    yield callApi(`/api-key/${id}`, 'DELETE');
    yield put({
      type: DEACTIVATE_API_KEY_SUCCESS,
      id,
    });
  } catch (err) {
    yield put({
      type: API_KEY_FAIL,
      error: err.message,
    });
  }
}

export default [
  takeLatest(LIST_API_KEY_REQUEST, listApiKey),
  takeLatest(CREATE_API_KEY_REQUEST, createApiKey),
  takeLatest(DEACTIVATE_API_KEY_REQUEST, deactivateApiKey),
];
