import styled from 'styled-components';

const StyledComponent = styled.div`
  span.error {
    display: block;
    margin-left: 0;
    margin-bottom: 15px;
  }
`;

export default StyledComponent;
