import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';

import createRootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const history = createBrowserHistory();
const enhancers = [];

/* eslint-disable no-underscore-dangle */
if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.devToolsExtension;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const store = createStore(
  createRootReducer(history), // root reducer with router state
  compose(
    applyMiddleware(
      sagaMiddleware,
      routerMiddleware(history), // for dispatching history actions
      thunkMiddleware,
      // ... other middlewares ...
    ),
    ...enhancers,
  ),
);

sagaMiddleware.run(rootSaga);

export {
  store,
  history,
};
