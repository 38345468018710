import { put, all } from 'redux-saga/effects';
import { GET_PROFILE_REQUEST } from '../reducers/user/constants';
import userSaga from './user';
import eshopSaga from './eshop';
import apiKeySaga from './apiKey';
import wishSaga from './wish';
import widgetSaga from './widget';
import { LIST_ESHOPS_REQUEST } from '../reducers/eshop/constants';

export default function* rootSaga() {
  yield all([...userSaga, ...eshopSaga, ...apiKeySaga, ...wishSaga, ...widgetSaga]);

  // Initialize after page reload
  yield put({ type: GET_PROFILE_REQUEST });
  // yield put({ type: LIST_ESHOPS_REQUEST });
}
