import styled from 'styled-components';

const StyledComponent = styled.div`
  button {
    margin-top: 0.5rem;
    margin-bottom: 5rem;
  }
`;

export default StyledComponent;
