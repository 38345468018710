export const GET_API_KEY_REQUEST = 'GET_API_KEY_REQUEST';
export const API_KEY_FAIL = 'API_KEY_FAIL';

export const LIST_API_KEY_REQUEST = 'LIST_API_KEY_REQUEST';
export const LIST_API_KEY_SUCCESS = 'LIST_API_KEY_SUCCESS';

export const CREATE_API_KEY_REQUEST = 'CREATE_API_KEY_REQUEST';
export const CREATE_API_KEY_SUCCESS = 'CREATE_API_KEY_SUCCESS';

export const DEACTIVATE_API_KEY_REQUEST = 'DEACTIVATE_API_KEY_REQUEST';
export const DEACTIVATE_API_KEY_SUCCESS = 'DEACTIVATE_API_KEY_SUCCESS';
