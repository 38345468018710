export default {
  'app.name': 'Wowee partners',
  'common.create': 'Vytvořit',
  'common.no': 'Ne',
  'common.yes': 'Ano',
  'common.add': 'Přidat',
  'common.cancel': 'Zrušit',
  'common.close': 'Zavřít',
  'common.share': 'Sdílet',
  'common.confirm': 'Potvrdit',
  'common.select': 'Zvolit',
  'common.next': 'Dále',
  'common.back': 'Zpět',
  'common.prevlabel': 'Předchozí',
  'common.nextlabel': 'Další',
  'common.save': 'Uložit',
  'common.saving': 'Ukládám ...',
  'common.saved': 'Uloženo',
  'common.loading': 'Načítání',
  'common.accept': 'Přijmout',
  'common.decline': 'Odmítnout',
  'common.tryAgain': 'Zkusit znovu',
  'common.selectAll': 'Vybrat vše',
  'common.deselectAll': 'Odebrat vše',
  'common.error': 'Něco se pokazilo.',
  'common.loadingError': 'Ouha, data se nepodařilo načíst',
  'common.submit': 'Odeslat',
  'common.email': 'E-mail',
  'common.or': 'Nebo',
  'common.password': 'Heslo',
  'common.password-again': 'Heslo znovu',

  'homepage.col1.header': 'Vítejte na Wowee partners!',
  'homepage.col1.body': '<p>Vítejte na partnerské části <a href="https://wowee.cz">Wowee</a>.</p> <br /> <p> V současné době je přístupná pouze eshopům běžící na platformě <a href="https://shoptet.cz">Shoptet.cz</a>. Těm prostřednictvím <a href="https://doplnky.shoptet.cz/wowee">doplňku</a> umožňuje vytvářet finanční sbírky na dárky mezi přáteli a přivést tak nové zákazníky.</p> <br /> <p>V blízké době umožníme partnerům využít nástroje Wowee nezávisle na využívané platformě včetně unikátní platební metody. Ta umožní platit penězi vybranými na <a href="https://wowee.cz">Wowee</a> přímo v eshopu.</p> <br /><p>Máte dotazy? Napište na <a href="mailto:michal.manena@wowee.cz">michal.manena@wowee.cz</a>. Rád vám odpovím.</p>',

  'homepage.col2.header': 'Jak funguje Wowee doplněk?',
  'homepage.col2.body': '<iframe src="https://www.youtube.com/embed/w8i-3BAOMH0" width="560" height="315" frameborder="0" allowfullscreen="allowfullscreen"></iframe></p>',

  'homepage.col3.header': 'Co je Wowee?',
  'homepage.col3.body': '<p>Wowee je aplikace, která plní lidem přání. Malá, velká i největší. Sami jsme v životě dostali spoustu nepoužitelných dárků a rozhodli jsme se to změnit. Rádi bychom dostávali to, po čem opravdu toužíme. Wowee spojuje ty, kteří vědí, co chtějí, s těmi, kteří jim to chtějí dopřát.</p> <br /><p>Hromadné financování je nejsnazší cestou k parádním dárkům. Kdysi pomohlo ke zrodu Národního divadla. A pomáhá dodnes. Třeba když se doma skládáte na dárek pro babičku, ve škole pro spolužáka a v práci pro kolegy. Stačí, když každý přihodí aspoň trošku, aby se celkově vybralo tolik, že je možné splnit velká i ještě větší přání.</p>',
  'homepage.col4.header': 'Jak funguje Wowee?',
  'homepage.col4.body': '<p><iframe src="https://www.youtube.com/embed/TnkOblHuCIc" width="560" height="315" frameborder="0" allowfullscreen="allowfullscreen"></iframe></p>',
  'homepage.siteUnavailable': 'Tato stránka je prozatím dostupná jen pro eshopy na platformě Shoptet. Máte-li dotaz nebo zájem o služby Wowee pro eshopy, napište na michal.manena@wowee.cz',

  'nav.homepage': 'Homepage',
  'nav.login': 'Přihlásit se',
  'nav.logout': 'Odhlásit se',
  'nav.register': 'Registrace eshopu',
  'nav.admin': 'Administrace',
  'nav.apiKeys': 'API klíče',

  'login.title': 'Přihlášení',
  'login.password.invalid': 'Neplatné heslo',
  'login.password.forget.1': 'Zapomněl jsi heslo? Tak si ho ',
  'login.password.forget.2': 'obnov',
  'login.noAccount.1': 'Nemáš u nás účet? Tak si ho ',
  'login.noAccount.2': 'rychle udělej',
  'login.account.1': 'Už u nás máš účet? Výborně, tak se můžeš rovnou ',
  'login.account.2': 'přihlásit',
  'login.request.401': 'Bohužel bylo zadáno nesprávné heslo',
  'login.request.404': 'Uživatel s tímto e-mailem nebyl bohužel nalezen',
  'login.request.406': 'Vaše emailová adresa nebyla doposud ověřena. Podívejte se do vaší emailové schránky. Pokud jste email ve schránce nenašli, kontaktujte podporu podpora@wowee.cz',

  'registration.title': 'Registrace eshopu',
  'registration.name': 'Název eshopu',
  'registration.company_name': 'Název společnosti provozující eshop',
  'registration.email': 'Kontaktní email',
  'registration.address': 'Sídlo společnosti',
  'registration.country_id': 'Země',
  'registration.tax_number': 'DIČ',
  'registration.registration_number': 'IČO',
  'registration.bank_account': 'Číslo bankovního účtu',
  'registration.image_logo': 'URL loga eshopu',
  'registration.site_url': 'URL eshopu',
  'registration.success_callback_url': 'Callback URL úspěšné platby',
  'registration.submit': 'Odeslat',

  'validate.required': 'Požadováno',
  'validate.password': 'Neplatné heslo',
  'validate.number': 'Musí být číslo',
  'validate.bank_account': 'Nesprávný tvar bankovního účtu',
  'validate.url': 'Nesprávný tvar URL',
  'validate.email': 'Nesprávný tvar emailu',
  'validate.dic': 'Nesprávný tvar DIČ',
  'validate.ico': 'Nesprávný tvar IČO',
  'validate.passwordsMatch': 'Zadaná hesla se neshodují',
  'validate.passwordLength': 'Heslo musí mít alespoň 6 znaků',

  'dashboard.title': 'Administrace eshopu',

  'dashboard.apikeys': 'Přehled API klíčů',
  'dashboard.apikeys.title': 'Název klíče',
  'dashboard.apikeys.key': 'API klíč',
  'dashboard.apikeys.date': 'Datum vytvoření',
  'dashboard.apikeys.status': 'Stav',
  'dashboard.apikeys.active': 'Aktivní',
  'dashboard.apikeys.action': 'Akce',
  'dashboard.apikeys.deactivated': 'Deaktivovaný',
  'dashboard.apikeys.deactivate': 'Deaktivovat',
  'dashboard.apikeys.generate': 'Nový API klíč',
  'dashboard.apikeys.submit': 'Vygenerovat',
  'dashboard.apikeys.noresults': 'Žádný klíč nebyl nalezen.',
  'dashboard.apikeys.success': 'Nový API klíč byl úspěšně vygenerován, můžete si ho zkopírovat:',

  'dashboard.wishes': 'Přehled přání',
  'dashboard.wishes.title': 'Titulek',
  'dashboard.wishes.state': 'Status',
  'dashboard.wishes.amount': 'Potřebná částka',
  'dashboard.wishes.created': 'Datum vytvoření',
  'dashboard.wishes.notset': 'Nespecifikovaná',
  'dashboard.wishes.noresults': 'Žádné přání nebylo doposud vyvořeno.',

  'dashboard.settings': 'Nastavení doplňku',
  'dashboard.settings.success': 'Úprava nastavení doplňku proběhla úspěšně',
  'dashboard.settings.widgetCreate': 'Vytvořit widget',
  'dashboard.settings.widgetScript': 'Script pro vložení na web',
  'dashboard.settings.widgetPreview': 'Náhled doplňku',
  'dashboard.form.addonFormat': 'Formát doplňku',
  'dashboard.form.addonColor': 'Barva doplňku',
  'dashboard.form.addonPositionMobile': 'Pozice',
  'dashboard.form.addonPositionDesktop': 'Pozice',
  'dashboard.form.addonOrientationMobile': 'Formát',
  'dashboard.form.addonOrientationDesktop': 'Formát',

  'dashboard.activation': 'Aktivace widgetu',
  'dashboard.activation.disable': 'Vypnout widget',
  'dashboard.activation.enable': 'Zapnout widget',

  'changePassword.title': 'Nastavení hesla',
  'changePassword.password.title': 'Nové heslo',
  'changePassword.passwordRepeat.title': 'Nové heslo znovu',
  'changePassword.success': 'Heslo bylo úspěšně změněno. Nyní se prosím přihlašte.',

  'apiKeys.title': 'Nastavení API klíčů',
};
