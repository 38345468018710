import { call } from 'redux-saga/effects';
import config from '../config';
import FetchError from '../errors/FetchError';

export function* callApi(endpoint, method = 'GET', data = null) {
  const headers = {
    'Content-Type': 'application/json',
  };
  const fetchResponse = yield call(fetch, config.appApiUrl + endpoint, {
    method,
    headers,
    credentials: 'include',
    body: data ? JSON.stringify(data) : null,
  });
  const response = yield call([fetchResponse, fetchResponse.json]);
  if (response && fetchResponse.ok) {
    return response.data;
  }
  throw new FetchError(response.error, fetchResponse.status);
}

export function* callServiceApi(endpoint, method = 'GET', data = null, token = null) {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  if (token) {
    headers.Authorization = `Token ${token}`;
  }
  const fetchResponse = yield call(fetch, config.serviceApiUrl + endpoint, {
    method,
    headers,
    credentials: 'include',
    body: data ? JSON.stringify(data) : null,
  });
  if (fetchResponse.status === 204) {
    return null;
  }
  const response = yield call([fetchResponse, fetchResponse.json]);
  if (response && fetchResponse.ok) {
    return response;
  }
  throw new FetchError(response.detail || response.message, fetchResponse.status);
}
