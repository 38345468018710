import styled from 'styled-components';

const StyledComponent = styled.div`
  .widget-script-preview {
    padding: 0.8rem 1rem;
    margin: 0;
    width: 100%;
    max-width: 50rem;
    outline: 0 !important;
    font-size: 0.93333rem;
    background-color: #f5f5f5;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  #widget-preview {
    min-height: 200px;
  }

  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    margin: 0 0 2rem;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;

    a {
      position: relative;
      display: block;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      line-height: 1.25;
      color: ${props => props.theme.colors.primary};
      background-color: #fff;
      border: 1px solid #dee2e6;
      cursor: pointer;
      outline: none;
    }

    a:hover {
      color: ${props => props.theme.colors.primary};
      text-decoration: none;
      background-color: #e9ecef;
      border-color: #dee2e6;
    }

    .previous a {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    .next a {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    .active a {
      z-index: 1;
      color: #fff;
      background-color: ${props => props.theme.colors.primary};
      border-color: ${props => props.theme.colors.primary};
    }

    .disabled a {
      color: #6c757d;
      pointer-events: none;
      cursor: auto;
      background-color: #fff;
      border-color: #dee2e6;
    }
  }
`;

export default StyledComponent;
