import { LIST_WISHES_REQUEST } from './constants';

function listWishes(limit, offset) {
  return {
    type: LIST_WISHES_REQUEST,
    limit,
    offset,
  };
}

export default listWishes;
