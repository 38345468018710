
export default {
  appApiUrl: process.env.REACT_APP_API_URL || 'http://localhost:3001',
  serviceApiUrl: process.env.REACT_APP_SERVICE_API_URL || 'https://api.elateme.com',
  appDomain: process.env.REACT_APP_DOMAIN || 'localhost',
  woweeUrl: process.env.REACT_APP_WOWEE_URL || 'test.wowee.cz',
  isDevelopment: process.env.NODE_ENV === 'development',
  /*
  appApiUrl: 'https://papi.wowee.cz',
  serviceApiUrl: 'https://api.wowee.cz',
  appDomain: 'wowee.cz',
  isDevelopment: false,
   */
};
