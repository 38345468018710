import React from 'react';
import pt from 'prop-types';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import { addLocaleData, IntlProvider } from 'react-intl';

import enLocaleData from 'react-intl/locale-data/en';
import csLocaleData from 'react-intl/locale-data/cs';

import routes from './routes';
import { Error404 } from './screens';
import theme from './theme';
import czechMessages from './locales/cs';

addLocaleData([...enLocaleData, ...csLocaleData]);

const Wrapper = ({ store, history }) => (
  <Provider store={store}>
    <IntlProvider locale="cs" messages={czechMessages}>
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <Switch>
            {
              routes.map(({ path, exact, component }) => (
                <Route key={path} exact={exact} path={path} component={component} />
              ))
            }
            <Route component={Error404} />
          </Switch>
        </ConnectedRouter>
      </ThemeProvider>
    </IntlProvider>
  </Provider>
);

Wrapper.propTypes = {
  store: pt.object.isRequired, // eslint-disable-line react/forbid-prop-types
  history: pt.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default Wrapper;
