import styled from 'styled-components';

const StyledComponent = styled.div`
.api-key-preview {
  display: block;
  margin-top: 0.5rem;
  width: 20rem;
  max-width: 20rem;
  min-width: 20rem;
}
`;

export default StyledComponent;
