import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';

const StyledComponent = styled.p`
margin: 0.5em 0;
font-size: 1.4rem;
`;

const P = ({ children }) => (
  <StyledComponent>
    {children}
  </StyledComponent>
);

P.propTypes = {
  children: pt.node.isRequired,
};

export default P;
