import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';

const StyledComponent = styled.div`
display: flex;
flex-wrap: wrap;
margin-right: -15px;
margin-left: -15px;
`;

const Row = ({ children }) => (
  <StyledComponent>
    {children}
  </StyledComponent>
);

Row.propTypes = {
  children: pt.node.isRequired,
};

export default Row;
