import {
  userIsAuthenticated,
  userIsRegistered,
  userIsNotRegistered,
  userIsNotAuthenticated,
} from './utils/authWrapper';

import {
  Dashboard, Login, Homepage, ChangePassword, Registration, ApiKeys,
} from './screens';

const routes = [
  {
    path: '/',
    component: Homepage,
    exact: true,
  },
  {
    path: '/login',
    component: userIsNotAuthenticated(Login),
    exact: true,
  },
  {
    path: '/set-new-password/:hash',
    component: ChangePassword,
    exact: true,
  },
  {
    path: '/dashboard',
    component: userIsAuthenticated(userIsRegistered(Dashboard)),
    exact: true,
  },
  // {
  //   path: '/api-keys',
  //   component: userIsAuthenticated(userIsRegistered(ApiKeys)),
  //   exact: true,
  // },
  {
    path: '/registration',
    component: userIsAuthenticated(userIsNotRegistered(Registration)),
    exact: true,
  },
];

export default routes;
