import { fromJS } from 'immutable';

const validate = (data) => {
  const values = fromJS(data);
  const errors = {};
  if (!values.get('password')) {
    errors.password = 'validate.required';
  } else if (values.get('password').length < 6) {
    errors.password = 'validate.passwordLength';
  }
  if (!values.get('passwordRepeat')) {
    errors.passwordRepeat = 'validate.required';
  } else if (values.get('password') !== values.get('passwordRepeat')) {
    errors.passwordRepeat = 'validate.passwordsMatch';
  }
  return errors;
};

export default validate;
