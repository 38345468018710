import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const StyledComponent = styled.span`
a {
  color: #7e3f91;
  font-weight: 700;
  text-decoration: none;
}
a:hover {
  color: #ffd200;
}
`;

const A = props => (
  <StyledComponent>
    <NavLink {...props}>{props.children}</NavLink>
  </StyledComponent>
);

A.propTypes = {
  children: pt.node.isRequired,
};

export default A;
