import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';

const StyledComponent = styled.div`
flex-basis: 0;
flex-grow: 1;
max-width: 100%;
position: relative;
width: 100%;
padding-right: 15px;
padding-left: 15px;
`;

const Column = ({ children }) => (
  <StyledComponent>
    {children}
  </StyledComponent>
);

Column.propTypes = {
  children: pt.node.isRequired,
};

export default Column;
