import React from 'react';
import pt from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { injectIntl, intlShape } from 'react-intl';

import StyledComponent from './styles';
import validate from './validate';
import FormField from '../FormField';
import Button from '../../Atoms/Button';

const countries = [
  { id: 'cz', name: 'Czech Republic' },
  { id: 'usa', name: 'United States of America' },
  { id: 'sk', name: 'Slovakia' },
];

const RegistrationForm = (props) => {
  const { intl, handleSubmit, submitting } = props;
  return (
    <StyledComponent>
      <form onSubmit={handleSubmit}>
        <Field
          name="name"
          type="text"
          component={FormField}
          label={intl.formatMessage({ id: 'registration.name' })}
        />
        <Field
          name="company_name"
          type="text"
          component={FormField}
          label={intl.formatMessage({ id: 'registration.company_name' })}
        />
        <Field
          name="eshop_email"
          type="email"
          component={FormField}
          label={intl.formatMessage({ id: 'registration.email' })}
        />
        <Field
          name="address"
          type="text"
          component={FormField}
          label={intl.formatMessage({ id: 'registration.address' })}
        />
        <Field
          name="country_id"
          type="select"
          component={FormField}
          label={intl.formatMessage({ id: 'registration.country_id' })}
        >
          {countries.map(country => (
            <option key={country.id} value={country.id}>
              {country.name}
            </option>
          ))}
        </Field>
        <Field
          name="tax_number"
          type="text"
          component={FormField}
          label={intl.formatMessage({ id: 'registration.tax_number' })}
        />
        <Field
          name="registration_number"
          type="number"
          component={FormField}
          label={intl.formatMessage({ id: 'registration.registration_number' })}
        />
        <Field
          name="bank_account"
          type="text"
          component={FormField}
          label={intl.formatMessage({ id: 'registration.bank_account' })}
        />
        <Field
          name="site_url"
          type="text"
          component={FormField}
          label={intl.formatMessage({ id: 'registration.site_url' })}
        />
        <Field
          name="image_logo"
          type="text"
          component={FormField}
          label={intl.formatMessage({ id: 'registration.image_logo' })}
        />
        {/* <Field
          name="success_callback_url"
          type="text"
          component={FormField}
          label={intl.formatMessage({ id: 'registration.success_callback_url' })}
        /> */}
        <Button type="submit" disabled={submitting}>
          {intl.formatMessage({ id: 'registration.submit' })}
        </Button>
      </form>
    </StyledComponent>
  );
};

RegistrationForm.propTypes = {
  intl: intlShape.isRequired,
  handleSubmit: pt.func.isRequired,
  submitting: pt.bool.isRequired,
};

export default reduxForm({
  form: 'registrationForm',
  validate,
})(injectIntl(RegistrationForm));
