import styled from 'styled-components';

const StyledComponent = styled.div`
  label {
    display: block;
    font-size: 1.06667rem;
    font-weight: 300;
    color: ${props => props.theme.colors.primary};
  }

  input {
    padding: 0.8rem 1rem;
    margin: 0;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    width: 100%;
    max-width: 30rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    font: inherit;
    outline: 0 !important;
    font-size: 0.93333rem;
    -webkit-transition: background-color 0.3s, box-shadow 0.3s, border-color 0.3s;
    transition: background-color 0.3s, box-shadow 0.3s, border-color 0.3s;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }

  select {
    padding: 0.8rem 1rem;
    margin: 0;
    height: 35px;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    width: 100%;
    max-width: 32rem;
    box-shadow: none;
    font: inherit;
    outline: 0 !important;
    font-size: 0.93333rem;
    -webkit-transition: background-color 0.3s, box-shadow 0.3s, border-color 0.3s;
    transition: background-color 0.3s, box-shadow 0.3s, border-color 0.3s;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }

  .error {
    color: red;
    margin-left: 1rem;
  }
`;

export default StyledComponent;
