import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';

const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  allowRedirectBack: false,
  authenticatingSelector: state => state.user.get('loading'),
  authenticatedSelector: state => state.user.get('tokenValidity'),
  // AuthenticatingComponent: null,
  wrapperDisplayName: 'UserIsAuthenticated',
});

const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: '/',
  allowRedirectBack: false,
  authenticatingSelector: state => state.user.get('loading'),
  authenticatedSelector: state => !state.user.get('tokenValidity'),
  // AuthenticatingComponent: null,
  wrapperDisplayName: 'UserIsAuthenticated',
});

const userIsRegistered = connectedRouterRedirect({
  redirectPath: '/registration',
  authenticatingSelector: state => state.eshop.get('loading'),
  authenticatedSelector: state => state.eshop.get('eshops') && Object.keys(state.eshop.get('eshops')).length > 0,
  wrapperDisplayName: 'UserIsRegistered',
  allowRedirectBack: false,
});

const userIsNotRegistered = connectedRouterRedirect({
  redirectPath: '/dashboard',
  allowRedirectBack: false,
  authenticatingSelector: state => state.eshop.get('loading'),
  authenticatedSelector: state => !state.eshop.get('eshops') || Object.keys(state.eshop.get('eshops')).length === 0,
  wrapperDisplayName: 'UserIsNotRegistered',
});

export {
  userIsAuthenticated,
  userIsRegistered,
  userIsNotRegistered,
  userIsNotAuthenticated,
};
