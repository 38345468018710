import pt from 'prop-types';

class FetchError extends Error {
  constructor(message, code) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    // Custom debugging information
    this.code = code;
  }
}

const errorShape = pt.shape({
  message: pt.string,
  code: pt.number,
});

export default FetchError;

export {
  errorShape,
};
