import { fromJS } from 'immutable';

const validate = (data) => {
  const values = fromJS(data);
  const errors = {};
  if (!values.get('password')) {
    errors.password = 'validate.password';
  }
  if (!values.get('email')) {
    errors.email = 'validate.email';
  }
  return errors;
};

export default validate;
