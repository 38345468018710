import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';

const StyledHeader = styled.h1`
  margin:0.4em;
  margin-top: 35px;
  text-align:center;
  font-family: "Lobster", sans-serif;
  font-size: 64px;
  color:#7e3f91;
  font-weight:400;
`;

const H1 = ({ children }) => (
  <StyledHeader>
    {children}
  </StyledHeader>
);

H1.propTypes = {
  children: pt.string.isRequired,
};

export default H1;
