import { fromJS } from 'immutable';
import { LIST_WISHES_REQUEST, LIST_WISHES_SUCCESS, LIST_WISHES_ERROR } from './constants';

const initialState = fromJS({
  error: null,
  wishes: null,
  loading: false,
  count: 0,
  limit: 10,
});

export const actionHandlers = {
  [LIST_WISHES_REQUEST](state) {
    return state.merge({
      error: null,
      loading: true,
    });
  },
  [LIST_WISHES_SUCCESS](state, { wishes, count }) {
    return state.merge({
      wishes,
      count,
      loading: false,
    });
  },
  [LIST_WISHES_ERROR](state, { error }) {
    return state.merge({
      error,
      loading: false,
    });
  },
};

function wishReducer(state = initialState, action) {
  const { type } = action;
  if (type in actionHandlers) {
    return actionHandlers[type](state, action);
  }
  return state;
}

export default wishReducer;
