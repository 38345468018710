import styled from 'styled-components';

const StyledComponent = styled.div`
  text-align: center;
  form {
    margin-bottom: 30px;
  }
`;

export default StyledComponent;
