import config from '../config';

/**
 * Cookie setter function
 *
 * @export
 * @param {any} cname
 * @param {any} cvalue
 * @param {number} [exhours=12]
 */

export function setCookie(cname, cvalue, exhours = 12) {
  const date = new Date();
  date.setTime(date.getTime() + (exhours * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/;domain=${config.appDomain}`;
}

/**
 * Cookie getter function
 *
 * @export
 * @param {any} cname
 * @returns
 */
export function getCookie(cname) {
  const name = `${cname}=`;
  const ca = decodeURIComponent(document.cookie).split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

/**
 * Remove cookie function
 *
 * @export
 * @param {any} cname
 */
export function removeCookie(cname) {
  setCookie(cname, '', 0);
}
