import styled from 'styled-components';

const StyledComponent = styled.div`
  .api-key-preview {
    padding: 0.8rem 1rem;
    margin: 0;
    width: 100%;
    max-width: 30rem;
    outline: 0 !important;
    font-size: 0.93333rem;
    background-color: #f5f5f5;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    margin-top: 0.5rem;
  }
`;

export default StyledComponent;
