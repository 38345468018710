import { fromJS } from 'immutable';
import {
  LIST_API_KEY_SUCCESS,
  CREATE_API_KEY_SUCCESS,
  API_KEY_FAIL,
  LIST_API_KEY_REQUEST,
  CREATE_API_KEY_REQUEST,
  DEACTIVATE_API_KEY_SUCCESS,
  DEACTIVATE_API_KEY_REQUEST,
} from './constants';

const initialState = fromJS({
  error: null,
  apiKeyData: null,
  loading: false,
  apiKeyPreview: null,
});

export const actionHandlers = {
  [LIST_API_KEY_REQUEST](state) {
    return state.merge({
      error: null,
      loading: true,
      apiKeyData: null,
    });
  },
  [DEACTIVATE_API_KEY_REQUEST](state) {
    return state.merge({
      error: null,
      loading: true,
    });
  },
  [DEACTIVATE_API_KEY_SUCCESS](state, { id }) {
    return state.merge({
      error: null,
      loading: false,
    }).updateIn(
      [
        'apiKeyData',
        state.get('apiKeyData').findIndex(item => item.id === id),
        'is_active',
      ],
      () => false,
    );
  },
  [LIST_API_KEY_SUCCESS](state, { apiKeyData }) {
    return state.merge({
      apiKeyData,
      error: null,
      loading: false,
    });
  },
  [CREATE_API_KEY_REQUEST](state) {
    return state.merge({
      error: null,
      loading: true,
      apiKeyPreview: null,
    });
  },
  [CREATE_API_KEY_SUCCESS](state, { apiKeyData }) {
    const newState = state.merge({
      apiKeyPreview: apiKeyData.api_key,
      loading: false,
      error: null,
    });
    if (state.get('apiKeyData') === null) {
      newState.set('apiKeyData', [apiKeyData]);
    } else {
      newState.update('apiKeyData', arr => arr.push(apiKeyData));
    }
    return newState;
  },
  [API_KEY_FAIL](state, { error }) {
    return state.merge({
      error,
      loading: false,
    });
  },
};

function apiReducer(state = initialState, action) {
  const { type } = action;
  if (type in actionHandlers) {
    return actionHandlers[type](state, action);
  }
  return state;
}

export default apiReducer;
