const prefix = 'WIDGET_REDUCER_';

export const UPDATE_WIDGET_REQUEST = `${prefix}UPDATE_WIDGET_REQUEST`;
export const UPDATE_WIDGET_SUCCESS = `${prefix}UPDATE_WIDGET_SUCCESS`;
export const UPDATE_WIDGET_FAIL = `${prefix}UPDATE_WIDGET_FAIL`;

export const GET_WIDGET_REQUEST = `${prefix}GET_WIDGET_REQUEST`;
export const GET_WIDGET_SUCCESS = `${prefix}GET_WIDGET_SUCCESS`;
export const GET_WIDGET_FAIL = `${prefix}GET_WIDGET_FAIL`;

export const GET_POSITIONS_REQUEST = `${prefix}GET_POSITIONS_REQUEST`;
export const GET_POSITIONS_SUCCESS = `${prefix}GET_POSITIONS_SUCCESS`;
export const GET_POSITIONS_FAIL = `${prefix}GET_POSITIONS_FAIL`;
