import React from 'react';
import pt from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import StyledComponent from './styles';

function formatDate(value) {
  const date = new Date(value);
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours()}:${
    date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`
  }`;
}

const WishView = ({ data, intl }) => (
  <StyledComponent>
    {data && data.length ? (
      <div className="table-responsive">
        <table>
          <tbody>
            <tr>
              <th>{intl.formatMessage({ id: 'dashboard.wishes.title' })}</th>
              <th>{intl.formatMessage({ id: 'dashboard.wishes.state' })}</th>
              <th>{intl.formatMessage({ id: 'dashboard.wishes.amount' })}</th>
              <th>{intl.formatMessage({ id: 'dashboard.wishes.created' })}</th>
            </tr>
            {data.map(wish => (
              <tr key={wish.id}>
                <td>{wish.title}</td>
                <td>{wish.wish_state_id}</td>
                <td>
                  {wish.amount_needed
                    ? `${wish.amount_needed} ${wish.currency_id.toUpperCase()}`
                    : intl.formatMessage({ id: 'dashboard.wishes.notset' })}
                </td>
                <td>{formatDate(wish.date_created)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <p>{intl.formatMessage({ id: 'dashboard.wishes.noresults' })}</p>
    )}
  </StyledComponent>
);

WishView.defaultProps = {
  data: [],
};

WishView.propTypes = {
  intl: intlShape.isRequired,
  data: pt.instanceOf(Array),
};

export default injectIntl(WishView);
