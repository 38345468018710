import { REGISTER_ESHOP_REQUEST, UPDATE_ESHOP_REQUEST, LIST_ESHOPS_REQUEST, SET_ACTIVE_ESHOP } from './constants';

export function registerEshop(values) {
  return {
    type: REGISTER_ESHOP_REQUEST,
    values,
  };
}

export function updateEshop(values) {
  return {
    type: UPDATE_ESHOP_REQUEST,
    values,
  };
}

export function setActiveEshop(eshopId) {
  return {
    type: SET_ACTIVE_ESHOP,
    eshopId,
  };
}

export function listEshops() {
  return {
    type: LIST_ESHOPS_REQUEST,
  };
}
