import styled from 'styled-components';

const StyledComponent = styled.div`
  .table-responsive {
    overflow: auto;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.06);
    padding-bottom: 0.25rem;
    margin-bottom: 2rem;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  tr:last-child {
    border: none;
  }

  td,
  th {
    padding: 1rem;
    text-align: center;
    vertical-align: middle;
  }

  textarea {
    width: 20rem;
    max-width: 20rem;
    min-width: 20rem;
  }
`;

export default StyledComponent;
