import React from 'react';
import pt from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { injectIntl, intlShape } from 'react-intl';

import StyledComponent from './styles';
import validate from './validate';
import FormField from '../FormField';
import Button from '../../Atoms/Button';

const ApiForm = (props) => {
  const {
    intl, handleSubmit, submitting, apiKeyPreview,
  } = props;
  return (
    <StyledComponent>
      <form onSubmit={handleSubmit}>
        <Field
          name="title"
          type="text"
          component={FormField}
          label={intl.formatMessage({ id: 'dashboard.apikeys.title' })}
        />
        {
          apiKeyPreview ? (
            <textarea className="api-key-preview" readOnly="readonly" value={apiKeyPreview} />
          ) : null
        }
        <Button type="submit" disabled={submitting}>
          {intl.formatMessage({ id: 'dashboard.apikeys.submit' })}
        </Button>
      </form>
    </StyledComponent>
  );
};

ApiForm.defaultProps = {
  apiKeyPreview: null,
};

ApiForm.propTypes = {
  intl: intlShape.isRequired,
  handleSubmit: pt.func.isRequired,
  submitting: pt.bool.isRequired,
  apiKeyPreview: pt.string,
};

export default reduxForm({
  form: 'apiForm',
  validate,
})(injectIntl(ApiForm));
