import styled from 'styled-components';

const StyledComponent = styled.div`
  margin-top: 2rem;
  button {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
`;

export default StyledComponent;
