import {
  LOG_OUT_REQUEST,
  LOG_IN_REQUEST,
  CHANGE_PASSWORD_REQUEST,
} from './constants';


export function logOut() {
  return {
    type: LOG_OUT_REQUEST,
  };
}

export function changePassword(password, passwordRepeat, hash) {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    password,
    passwordRepeat,
    hash,
  };
}

export const logIn = (email, password) => ({
  type: LOG_IN_REQUEST,
  email,
  password,
});
