const prefix = 'USER_REDUCER_';

export const GET_PROFILE_REQUEST = `${prefix}GET_PROFILE_REQUEST`;
export const GET_PROFILE_SUCCESS = `${prefix}GET_PROFILE_SUCCESS`;
export const GET_PROFILE_FAIL = `${prefix}GET_PROFILE_FAIL`;

export const LOG_OUT_REQUEST = `${prefix}LOG_OUT_REQUEST`;
export const LOG_OUT_SUCCESS = `${prefix}LOG_OUT_SUCCESS`;
export const LOG_OUT_FAIL = `${prefix}LOG_OUT_FAIL`;

export const LOG_IN_REQUEST = `${prefix}LOG_IN_REQUEST`;
export const LOG_IN_SUCCESS = `${prefix}LOG_IN_SUCCESS`;
export const LOG_IN_FAIL = `${prefix}LOG_IN_FAIL`;

export const CHANGE_PASSWORD_REQUEST = `${prefix}CHANGE_PASSWORD_REQUEST`;
export const CHANGE_PASSWORD_SUCCESS = `${prefix}CHANGE_PASSWORD_SUCCESS`;
export const CHANGE_PASSWORD_FAIL = `${prefix}CHANGE_PASSWORD_FAIL`;
