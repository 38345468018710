import {
  CREATE_API_KEY_REQUEST,
  DEACTIVATE_API_KEY_REQUEST,
  LIST_API_KEY_REQUEST,
} from './constants';

export function createApiKey(values) {
  return {
    type: CREATE_API_KEY_REQUEST,
    values,
  };
}

export function deactivateApiKey(id) {
  return {
    type: DEACTIVATE_API_KEY_REQUEST,
    id,
  };
}

export function listApiKeys() {
  return {
    type: LIST_API_KEY_REQUEST,
  };
}
