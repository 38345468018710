import React from 'react';
import pt from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';

import StyledComponent from './styles';
import {
  Layout, H1, Container, Alert,
} from '../../components';
import RegistrationForm from '../../components/forms/RegistrationForm';
import { registerEshop } from '../../reducers/eshop/actions';

const Registration = ({ intl, handleRegistration, submitErr }) => (
  <Layout>
    <StyledComponent>
      <Container>
        <H1>{intl.formatMessage({ id: 'registration.title' })}</H1>
        {submitErr ? <Alert type="error">{intl.formatMessage({ id: 'common.error' })}</Alert> : null}
        <RegistrationForm initialValues={fromJS({ country_id: 'cz' })} onSubmit={handleRegistration} />
      </Container>
    </StyledComponent>
  </Layout>
);

const mapStateToProps = state => ({
  submitErr: state.eshop.get('error'),
});

const mapDispatchToProps = dispatch => ({
  handleRegistration: (values) => {
    dispatch(registerEshop(values));
  },
});

Registration.defaultProps = {
  submitErr: null,
};

Registration.propTypes = {
  intl: intlShape.isRequired,
  submitErr: pt.string,
  handleRegistration: pt.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Registration));
