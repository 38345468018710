import React from 'react';
import pt from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { injectIntl, intlShape } from 'react-intl';

import StyledComponent from './styles';
import validate from './validate';
import FormField from '../FormField';
import Button from '../../Atoms/Button';

const LoginForm = (props) => {
  const {
    intl, handleSubmit, submitting,
  } = props;
  return (
    <StyledComponent>
      <form onSubmit={handleSubmit}>
        <Field
          name="email"
          type="email"
          component={FormField}
          placeholder={intl.formatMessage({ id: 'common.email' })}
        />
        <Field
          name="password"
          type="password"
          component={FormField}
          placeholder={intl.formatMessage({ id: 'common.password' })}
        />
        <Button type="submit" disabled={submitting}>
          {intl.formatMessage({ id: 'common.submit' })}
        </Button>
      </form>
    </StyledComponent>
  );
};

LoginForm.defaultProps = {};

LoginForm.propTypes = {
  intl: intlShape.isRequired,
  handleSubmit: pt.func.isRequired,
  submitting: pt.bool.isRequired,
};

export default reduxForm({
  form: 'LoginForm',
  validate,
})(injectIntl(LoginForm));
