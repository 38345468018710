import React from 'react';
import StyledComponent from './styles';
import { Layout, H1, Container } from '../../components';

const Error404 = () => (
  <Layout>
    <StyledComponent>
      <Container>
        <H1>404</H1>
      </Container>
    </StyledComponent>
  </Layout>
);

export default Error404;
