import React from 'react';
import pt from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';

import StyledComponent from './styles';
// import validate from './validate';
import FormField from '../FormField';
import Button from '../../Atoms/Button';

const colors = [
  {
    id: 'default',
    name: 'fialová',
    value: 'default',
  },
  {
    id: 'white',
    name: 'bílá',
    value: 'white',
  },
];

const formats = [
  {
    id: 'landscape',
    name: 'na šířku 260x170 pixelů',
    value: 'landscape',
  },
  {
    id: 'portrait',
    name: 'na výšku 170x240  pixelů',
    value: 'portrait',
  },
  {
    id: 'rectangle',
    name: 'čtverec 170x170 pixelů',
    value: 'rectangle',
  },
];

const Fieldset = styled.fieldset`
    width: 100%;
    max-width: 30rem;
    margin-bottom: 20px;
`;

const Legend = styled.legend`
    color: ${props => props.theme.colors.primary};
`;

const AddonForm = (props) => {
  const { intl, handleSubmit, submitting, positionsDesktop, positionsMobile, isShoptet } = props;
  return (
    <StyledComponent>
      <form onSubmit={handleSubmit}>
        <Fieldset>
          <Legend>Obecné nastavení</Legend>
          <Field
            name="color_scheme"
            type="select"
            component={FormField}
            label={intl.formatMessage({ id: 'dashboard.form.addonColor' })}
          >
            {colors.map(({ id, value, name }) => (
              <option key={id} value={value}>
                {name}
              </option>
            ))}
          </Field>
        </Fieldset>
        <Fieldset>
          <Legend>Zobrazení na počítači</Legend>
          {
            isShoptet ? (
              <Field
                name="widget_template_position_desktop_id"
                type="select"
                component={FormField}
                label={intl.formatMessage({ id: 'dashboard.form.addonPositionDesktop' })}
              >
                {positionsDesktop.map(({ id, value, name }) => (
                  <option key={id} value={value}>
                    {name}
                  </option>
                ))}
              </Field>
            ) : null
          }
          <Field
            name="orientation_desktop"
            type="select"
            component={FormField}
            label={intl.formatMessage({ id: 'dashboard.form.addonOrientationDesktop' })}
          >
            {formats.map(({ id, value, name }) => (
              <option key={id} value={value}>
                {name}
              </option>
            ))}
          </Field>
        </Fieldset>
        <Fieldset>
          <Legend>Zobrazení na mobilu</Legend>
          {
            isShoptet ? (
              <Field
                name="widget_template_position_mobile_id"
                type="select"
                component={FormField}
                label={intl.formatMessage({ id: 'dashboard.form.addonPositionMobile' })}
              >
                {positionsMobile.map(({ id, value, name }) => (
                  <option key={id} value={value}>
                    {name}
                  </option>
                ))}
              </Field>
            ) : null
          }
          <Field
            name="orientation_mobile"
            type="select"
            component={FormField}
            label={intl.formatMessage({ id: 'dashboard.form.addonOrientationMobile' })}
          >
            {formats.map(({ id, value, name }) => (
              <option key={id} value={value}>
                {name}
              </option>
            ))}
          </Field>
        </Fieldset>

        <Button type="submit" disabled={submitting}>
          {intl.formatMessage({ id: 'common.submit' })}
        </Button>
      </form>
    </StyledComponent>
  );
};

AddonForm.propTypes = {
  intl: intlShape.isRequired,
  handleSubmit: pt.func.isRequired,
  submitting: pt.bool.isRequired,
  positionsDesktop: pt.array,
  positionsMobile: pt.array,
};

AddonForm.defaultProps = {
  positionsDesktop: [],
  positionsMobile: [],
};

export default reduxForm({
  form: 'addonForm',
  // validate,
})(injectIntl(AddonForm));
