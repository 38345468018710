import styled from 'styled-components';

const StyledComponent = styled.header`
color: white;
/* font-size: ( 18 / 15 ) * 1rem; */
font-weight: 300;
background: ${props => props.theme.colors.primary};

a { text-decoration: none; }

.header-container {
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-logo {
  margin: 0 2rem 0 0;
  font-size: 1em;
  img {
    height:35px;
  }
  .logo-bubble {
    height:50px;
    margin-bottom:-10px;
  }
}

.Dropdown-root {
  margin-left: 30px;
}

.Dropdown-control {
  width: 230px;
  background-color: #7e3f91;
  color: #fff;
  cursor: pointer;
  border: 2px solid #ffffff;
  border-radius: 5px;
}

.Dropdown-arrow {
  right: -40px;
  top: -10px;
  border-color: #fff transparent transparent;
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #fff;
}

.header-menu {
  display: flex;
  list-style-type: none;
  align-items: center;
  flex-grow: 1;

  &:last-child {
    justify-content: flex-end;
  }

  a, button {
    display: block;
    position: relative;
    padding: 0 1rem;
    line-height: 4rem;
    font-size:1.4rem;
    color: white;
    &.active {
      color: ${props => props.theme.colors.yellow};
    }
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    &:focus, &:active {
      outline: none;
    }
  }
}
`;

export default StyledComponent;
