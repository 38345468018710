import { UPDATE_WIDGET_REQUEST, GET_WIDGET_REQUEST, GET_POSITIONS_REQUEST } from './constants';

export function updateWidget(values) {
  return {
    type: UPDATE_WIDGET_REQUEST,
    values,
  };
}

export function getWidget() {
  return {
    type: GET_WIDGET_REQUEST,
  };
}

export function getWidgetPositions() {
  return {
    type: GET_POSITIONS_REQUEST,
  };
}
