import { fromJS } from 'immutable';
import {
  UPDATE_WIDGET_FAIL,
  UPDATE_WIDGET_REQUEST,
  UPDATE_WIDGET_SUCCESS,
  GET_WIDGET_FAIL,
  GET_WIDGET_REQUEST,
  GET_WIDGET_SUCCESS,
  GET_POSITIONS_REQUEST,
  GET_POSITIONS_FAIL,
  GET_POSITIONS_SUCCESS,
} from './constants';

const initialState = fromJS({
  error: null,
  data: null,
  loading: false,
  positions: null,
});

export const actionHandlers = {
  [GET_WIDGET_REQUEST](state) {
    return state.merge({
      data: null,
      loading: true,
      error: null,
    });
  },
  [GET_WIDGET_SUCCESS](state, { data }) {
    return state.merge({
      data,
      loading: false,
    });
  },
  [GET_WIDGET_FAIL](state, { error }) {
    return state.merge({
      error,
      loading: false,
    });
  },
  [GET_POSITIONS_REQUEST](state) {
    return state.merge({
      positions: null,
      loading: true,
      error: null,
    });
  },
  [GET_POSITIONS_SUCCESS](state, { positions }) {
    return state.merge({
      positions,
      loading: false,
    });
  },
  [GET_POSITIONS_FAIL](state, { error }) {
    return state.merge({
      error,
      loading: false,
    });
  },
  [UPDATE_WIDGET_REQUEST](state) {
    return state.merge({
      loading: true,
      error: null,
    });
  },
  [UPDATE_WIDGET_SUCCESS](state, { data }) {
    return state.merge({
      error: null,
      loading: false,
      data,
    });
  },
  [UPDATE_WIDGET_FAIL](state, { error }) {
    return state.merge({
      error,
      loading: false,
    });
  },
};

function reducer(state = initialState, action) {
  const { type } = action;
  if (type in actionHandlers) {
    return actionHandlers[type](state, action);
  }
  return state;
}

export default reducer;
