import {
  put, takeEvery, takeLatest,
} from 'redux-saga/effects';

import {
  REGISTER_ESHOP_REQUEST,
  REGISTER_ESHOP_SUCCESS,
  REGISTER_ESHOP_FAIL,
  UPDATE_ESHOP_REQUEST,
  UPDATE_ESHOP_FAIL,
  UPDATE_ESHOP_SUCCESS,
  LIST_ESHOPS_REQUEST,
  LIST_ESHOPS_SUCCESS,
  LIST_ESHOPS_FAIL,
} from '../reducers/eshop/constants';
import {
  GET_PROFILE_SUCCESS,
} from '../reducers/user/constants';
import { callApi } from './api';

export function* registerEshop({ values }) {
  try {
    const data = yield callApi('/eshop', 'PUT', values);
    yield put({
      type: REGISTER_ESHOP_SUCCESS,
      eshopData: data,
    });
  } catch (err) {
    yield put({
      type: REGISTER_ESHOP_FAIL,
      error: err.message,
    });
  }
}

export function* updateEshop({ values }) {
  try {
    const data = yield callApi(`/eshop/${values.id}`, 'POST', values);
    yield put({
      type: UPDATE_ESHOP_SUCCESS,
      eshopData: data,
    });
  } catch (err) {
    yield put({
      type: UPDATE_ESHOP_FAIL,
      error: err.message,
    });
  }
}

export function* listEshops() {
  try {
    const data = yield callApi('/eshop');
    yield put({
      type: LIST_ESHOPS_SUCCESS,
      eshops: data,
    });
  } catch (err) {
    yield put({
      type: LIST_ESHOPS_FAIL,
      error: err.message,
    });
  }
}

export default [
  takeEvery(REGISTER_ESHOP_REQUEST, registerEshop),
  takeEvery(UPDATE_ESHOP_REQUEST, updateEshop),
  takeLatest(LIST_ESHOPS_REQUEST, listEshops),
  takeLatest(GET_PROFILE_SUCCESS, listEshops),
  takeLatest(REGISTER_ESHOP_SUCCESS, listEshops),
];
