import React from 'react';
import pt from 'prop-types';

import StyledComponent from './styles';
import { Header } from '../index';

const Layout = ({ children }) => (
  <StyledComponent>
    <Header />
    { children }
  </StyledComponent>
);

Layout.propTypes = {
  children: pt.node.isRequired,
};

export default Layout;
