import styled from 'styled-components';

const StyledComponent = styled.div`
display: flex;
flex-direction: column;
min-height: 100%;
background: ${props => props.theme.colors.background};
flex-grow: 1;
padding-bottom: 3rem;
`;

export default StyledComponent;
