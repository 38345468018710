import { put, takeLatest, select } from 'redux-saga/effects';
import {
  LIST_WISHES_REQUEST,
  LIST_WISHES_SUCCESS,
  LIST_WISHES_ERROR,
} from '../reducers/wish/constants';
import { callApi } from './api';
import { serializeData } from '../utils/utilities';

export const getActiveEshopId = state => state.eshop.get('activeEshopId');

export function* listWishes(values) {
  try {
    const eshopId = yield select(getActiveEshopId);
    const data = yield callApi(`/wishes?eshopId=${eshopId}&${values ? serializeData(values) : ''}`);
    yield put({
      type: LIST_WISHES_SUCCESS,
      wishes: data.wishes.rows,
      count: data.wishes.count,
    });
  } catch (err) {
    yield put({
      type: LIST_WISHES_ERROR,
      error: err.message,
    });
  }
}

export default [
  takeLatest(LIST_WISHES_REQUEST, listWishes),
];
