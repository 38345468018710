import React from 'react';
import pt from 'prop-types';
import { NavLink } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import StyledComponent from './styles';
import { Container } from '../index';
import logoText from './images/logo-text.svg';
import logo from './images/logo-bubble.svg';
import { logOut } from '../../reducers/user/actions';
import { setActiveEshop } from '../../reducers/eshop/actions';


const Header = ({ intl, isLoading, isLoggedIn, eshops, onLogOut, onEshopChange, activeEshopId }) => (
  <StyledComponent>
    <Container>
      <div className="header-container">
        <h1 className="header-logo">
          <a className="logo" href={eshops ? '/dashboard' : '/'}>
            <img src={logo} className="logo-bubble" alt={intl.formatMessage({ id: 'app.name' })} />
            <img src={logoText} alt={intl.formatMessage({ id: 'app.name' })} />
          </a>
        </h1>
        <div className="header-body">
          <ul className="header-menu">
            <li>
              <NavLink exact to="/" activeClassName="active">{intl.formatMessage({ id: 'nav.homepage' })}</NavLink>
            </li>
            {
              isLoggedIn && eshops && Object.keys(eshops).length > 0 && (
                <li>
                  <NavLink to="/dashboard" activeClassName="active">{intl.formatMessage({ id: 'nav.admin' })}</NavLink>
                </li>
              )
            }
            {
            /* temporarily commented
              isLoggedIn && isRegistered (
                <li>
                  <NavLink to="/api-keys" activeClassName="active">{intl.formatMessage({ id: 'nav.apiKeys' })}</NavLink>
                </li>
              )
             */
            }
            {
              isLoggedIn && eshops && !Object.keys(eshops).length && (
                <li>
                  <NavLink
                    to="/registration"
                    activeClassName="active"
                  >
                    {intl.formatMessage({ id: 'nav.register' })}
                  </NavLink>
                </li>
              )
            }
            {
              isLoggedIn
                ? (
                  <li>
                    <button
                      type="button"
                      onClick={() => onLogOut()}
                    >
                      {intl.formatMessage({ id: 'nav.logout' })}
                    </button>
                  </li>
                )
                : (
                  <li>
                    <NavLink
                      to="/login"
                      activeClassName="active"
                    >
                      {intl.formatMessage({ id: 'nav.login' })}
                    </NavLink>
                  </li>
                )
            }
            {
              isLoggedIn && eshops && Object.keys(eshops).length > 1 && (
                <Dropdown
                  options={Object.keys(eshops).map(id => ({ value: id, label: eshops[id].name }))}
                  onChange={onEshopChange}
                  value={activeEshopId}
                  placeholder="Select an option"
                />
              )
            }
          </ul>
        </div>
      </div>
    </Container>
  </StyledComponent>
);

const mapStateToProps = state => ({
  isLoading: state.user.get('loading'),
  isLoggedIn: state.user.get('tokenValidity'),
  eshops: state.eshop.get('eshops'),
  activeEshopId: state.eshop.get('activeEshopId'),
});

const mapDispatchToProps = dispatch => ({
  onLogOut: () => {
    dispatch(logOut());
  },
  onEshopChange: ({ value }) => {
    dispatch(setActiveEshop(value));
  },
});

Header.defaultProps = {
  isLoading: true,
  isLoggedIn: false,
  eshops: null,
  activeEshopId: null,
};

Header.propTypes = {
  intl: intlShape.isRequired,
  isLoading: pt.bool,
  onLogOut: pt.func.isRequired,
  isLoggedIn: pt.bool,
  eshops: pt.object,
  activeEshopId: pt.string,
  onEshopChange: pt.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Header));
