import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';

const StyledComponent = styled.button`
  display: inline-block;
  position: relative;
  padding: 0.6em 1.8em;
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  font-family: inherit;
  text-decoration: none;
  border: none;
  cursor: pointer;
  border-radius: 0.2em;
  background: ${props => props.theme.colors.primary};
`;

const Button = ({ type, disabled, children, onClick }) => (
  <StyledComponent type={type} disabled={disabled} onClick={() => onClick()}>
    {children}
  </StyledComponent>
);

Button.defaultProps = {
  disabled: false,
  onClick: () => {},
};

Button.propTypes = {
  type: pt.string.isRequired,
  disabled: pt.bool,
  children: pt.string.isRequired,
  onClick: pt.func,
};

export default Button;
