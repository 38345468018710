import { put, takeLatest } from 'redux-saga/effects';

import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  LOG_OUT_REQUEST,
  LOG_OUT_SUCCESS,
  LOG_OUT_FAIL,
  LOG_IN_REQUEST,
  LOG_IN_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
} from '../reducers/user/constants';
import { getCookie, removeCookie, setCookie } from '../utils/cookie';
import { callApi, callServiceApi } from './api';

export function* logIn({ password, email }) {
  try {
    const data = yield callServiceApi('/account/auth/email/login', 'POST', {
      password,
      email,
    });
    setCookie('apiToken', data.token);
    yield put({
      type: GET_PROFILE_REQUEST,
    });
  } catch (error) {
    yield put({
      type: LOG_IN_FAIL,
      error,
    });
  }
}

export function* logOut() {
  try {
    const token = getCookie('apiToken');
    yield callServiceApi('/account/auth/logout', 'POST', null, token);
    removeCookie('apiToken');
    yield put({
      type: LOG_OUT_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: LOG_OUT_FAIL,
      error,
    });
  }
}

export function* changePassword({ password, passwordRepeat, hash }) {
  try {
    yield callServiceApi(`/account/forgotten-password/${hash}`, 'PATCH', {
      password,
      'confirm-password': passwordRepeat,
    });
    yield put({
      type: CHANGE_PASSWORD_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: CHANGE_PASSWORD_FAIL,
      error: err.message,
    });
  }
}

export function* getUserProfile() {
  if (getCookie('apiToken') == null) {
    yield put({
      type: GET_PROFILE_FAIL,
    });
    return;
  }

  try {
    yield callApi('/user');
    yield put({
      type: GET_PROFILE_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: GET_PROFILE_FAIL,
      error: err.message,
    });
  }
}

export default [
  takeLatest(GET_PROFILE_REQUEST, getUserProfile),
  takeLatest(LOG_OUT_REQUEST, logOut),
  takeLatest(LOG_IN_REQUEST, logIn),
  takeLatest(CHANGE_PASSWORD_REQUEST, changePassword),
];
