import styled from 'styled-components';

const StyledComponent = styled.div`
  color:#313131;
  p {
  margin:0.5em 0;
  font-size:1.4rem;
  }
  a {
  color:#7e3f91;
  font-weight:700;
  text-decoration:none;
  }
  a:hover {
  color:#ffd200;
  }
`;

export default StyledComponent;
