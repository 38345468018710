import React from 'react';
import styled from 'styled-components';
import { intlShape } from 'react-intl';

const StyledComponent = styled.p`
  
`;

const Loading = ({ intl }) => (
  <StyledComponent>
    {intl.formatMessage({ id: 'common.loading' })}
    ...
  </StyledComponent>
);

Loading.propTypes = {
  intl: intlShape.isRequired,
};

export default Loading;
