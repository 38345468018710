import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';

const StyledHeader = styled.div`
  margin-top: 20px;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  &.success {
    color: #29721c;
    background-color: #d7f8dd;
    border-color: #bae9c0;
  }
  &.error {
    margin-left: 0 !important;
    color: #721c24 !important;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
`;

const Alert = ({ type, children }) => <StyledHeader className={type}>{children}</StyledHeader>;

Alert.propTypes = {
  type: pt.string.isRequired,
  children: pt.string.isRequired,
};

export default Alert;
