import React from 'react';
import pt from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import StyledComponent from './styles';

const FormField = (props) => {
  const {
    intl,
    input,
    label,
    type,
    placeholder,
    meta: { touched, error },
    children,
  } = props;
  return (
    <StyledComponent>
      <div>
        <label>
          {label}
          <div>
            {type === 'select' ? (
              <select {...input}>{children}</select>
            ) : (
              <input {...input} type={type} placeholder={placeholder || label} />
            )}
            {touched
              && (error && <span className="error">{intl.formatMessage({ id: error })}</span>)}
          </div>
        </label>
      </div>
    </StyledComponent>
  );
};

FormField.defaultProps = {
  input: '',
  label: '',
  type: '',
  meta: '',
  placeholder: '',
  children: [],
};

FormField.propTypes = {
  intl: intlShape.isRequired,
  input: pt.object,
  placeholder: pt.string,
  label: pt.string,
  type: pt.string,
  meta: pt.object,
  children: pt.array,
};

export default injectIntl(FormField);
