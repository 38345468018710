import {
  put, takeLatest, select,
} from 'redux-saga/effects';

import {
  UPDATE_WIDGET_REQUEST,
  UPDATE_WIDGET_FAIL,
  UPDATE_WIDGET_SUCCESS,
  GET_WIDGET_REQUEST,
  GET_WIDGET_FAIL,
  GET_WIDGET_SUCCESS,
  GET_POSITIONS_REQUEST,
  GET_POSITIONS_FAIL,
  GET_POSITIONS_SUCCESS,
} from '../reducers/widget/constants';
import { callApi } from './api';

export const getActiveEshopId = state => state.eshop.get('activeEshopId');

export function* updateWidget({ values }) {
  try {
    const eshopId = yield select(getActiveEshopId);
    const data = yield callApi(`/widget?eshopId=${eshopId}`, 'PUT', values);
    yield put({
      type: UPDATE_WIDGET_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({
      type: UPDATE_WIDGET_FAIL,
      error: err.message,
    });
  }
}

export function* getWidget() {
  try {
    const eshopId = yield select(getActiveEshopId);
    const data = yield callApi(`/widget?eshopId=${eshopId}`);
    yield put({
      type: GET_WIDGET_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({
      type: GET_WIDGET_FAIL,
      error: err.message,
    });
  }
}

export function* getWidgetPositions() {
  try {
    const eshopId = yield select(getActiveEshopId);
    const data = yield callApi(`/widget/positions?eshopId=${eshopId}`);
    yield put({
      type: GET_POSITIONS_SUCCESS,
      positions: data,
    });
  } catch (err) {
    yield put({
      type: GET_POSITIONS_FAIL,
      error: err.message,
    });
  }
}

export default [
  takeLatest(UPDATE_WIDGET_REQUEST, updateWidget),
  takeLatest(GET_WIDGET_REQUEST, getWidget),
  takeLatest(GET_POSITIONS_REQUEST, getWidgetPositions),
];
