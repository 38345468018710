import React, { Component } from 'react';
import pt from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';

import StyledComponent from './styles';
import {
  Layout, H1, Container, Alert,
} from '../../components';
import { PasswordForm } from '../../components/forms';
import { changePassword } from '../../reducers/user/actions';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmited: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(values) {
    const { handleUpdatePassword } = this.props;
    handleUpdatePassword(values.password, values.passwordRepeat);
    this.setState({
      formSubmited: true,
    });
  }

  render() {
    const {
      intl, submitErr, loading,
    } = this.props;
    const { formSubmited } = this.state;
    const isDone = !loading && formSubmited;

    return (
      <Layout>
        <StyledComponent>
          <Container>
            <H1>{intl.formatMessage({ id: 'changePassword.title' })}</H1>
            { isDone && submitErr ? (
              <Alert type="error">{intl.formatMessage({ id: 'common.error' })}</Alert>
            ) : null}
            {
              isDone && !submitErr ? (
                <Alert type="success">
                  {intl.formatMessage({ id: 'changePassword.success' })}
                </Alert>
              ) : null
            }
            <PasswordForm onSubmit={this.handleFormSubmit} />
          </Container>
        </StyledComponent>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.user.get('loading'),
  submitErr: state.user.get('error'),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleUpdatePassword: (password, passwordRepeat) => {
    dispatch(changePassword(password, passwordRepeat, ownProps.match.params.hash));
  },
});

ChangePassword.defaultProps = {
  submitErr: null,
  loading: false,
};

ChangePassword.propTypes = {
  handleUpdatePassword: pt.func.isRequired,
  submitErr: pt.string,
  intl: intlShape.isRequired,
  loading: pt.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ChangePassword));
