import React, { Component } from 'react';
import pt from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';

import StyledComponent from './styles';
import {
  Layout, H1, Container, Alert, A, P,
} from '../../components';
import { errorShape } from '../../errors/FetchError';
import { LoginForm } from '../../components/forms';
import { logIn } from '../../reducers/user/actions';
import config from "../../config";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmited: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(values) {
    const { onLogIn } = this.props;
    onLogIn(values.email, values.password);
    this.setState({
      formSubmited: true,
    });
  }

  render() {
    const {
      intl, submitErr, loading,
    } = this.props;
    const { formSubmited } = this.state;
    const isDone = !loading && formSubmited;

    return (
      <Layout>
        <StyledComponent>
          <Container>
            <H1>{intl.formatMessage({ id: 'login.title' })}</H1>

            {isDone && submitErr ? (
              <Alert type="error">
                {intl.formatMessage({
                  id: submitErr.code >= 400 && submitErr.code < 500 ? `login.request.${submitErr.code}` : 'common.error',
                })}
              </Alert>
            ) : null}
            <LoginForm onSubmit={this.handleFormSubmit} />
              {/* 
            <P>
              {intl.formatMessage({ id: 'login.noAccount.1' })}
              <A to="https://wowee.cz/sign-up">{intl.formatMessage({ id: 'login.noAccount.2' })}</A>
              !
            </P>
            */}
            <P>
              {intl.formatMessage({ id: 'login.password.forget.1' })}
              <a href={`${config.woweeUrl}/forgotten-password`}>{intl.formatMessage({ id: 'login.password.forget.2' })}</a>
              .
            </P>
          </Container>
        </StyledComponent>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.user.get('loading'),
  submitErr: state.user.get('error'),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLogIn: (password, email) => {
    dispatch(logIn(password, email));
  },
});

Login.defaultProps = {
  submitErr: null,
  loading: false,
};

Login.propTypes = {
  onLogIn: pt.func.isRequired,
  submitErr: errorShape,
  intl: intlShape.isRequired,
  loading: pt.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Login));
