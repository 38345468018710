import React from 'react';
import pt from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import StyledComponent from './styles';
import Button from '../Atoms/Button';

function formatDate(value) {
  const date = new Date(value);
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours()}:${
    date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`
  }:${date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`}`;
}

const ApiView = ({ data, intl, handleClick }) => (
  <StyledComponent>
    {data && data.length ? (
      <div className="table-responsive">
        <table>
          <tbody>
            <tr>
              <th>{intl.formatMessage({ id: 'dashboard.apikeys.title' })}</th>
              <th>{intl.formatMessage({ id: 'dashboard.apikeys.date' })}</th>
              <th>{intl.formatMessage({ id: 'dashboard.apikeys.status' })}</th>
              <th>{intl.formatMessage({ id: 'dashboard.apikeys.action' })}</th>
            </tr>
            {data.map(api => (
              <tr key={api.id}>
                <td>{api.title}</td>
                <td>{formatDate(api.created_at)}</td>
                <td>
                  {api.is_active
                    ? intl.formatMessage({ id: 'dashboard.apikeys.active' })
                    : intl.formatMessage({ id: 'dashboard.apikeys.deactivated' })}
                </td>
                <td>
                  {api.is_active ? (
                    <Button type="button" onClick={() => handleClick(api.id)}>
                      {intl.formatMessage({ id: 'dashboard.apikeys.deactivate' })}
                    </Button>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <p>{intl.formatMessage({ id: 'dashboard.apikeys.noresults' })}</p>
    )}
  </StyledComponent>
);

ApiView.defaultProps = {
  data: [],
};

ApiView.propTypes = {
  intl: intlShape.isRequired,
  data: pt.instanceOf(Array),
  handleClick: pt.func.isRequired,
};

export default injectIntl(ApiView);
