import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';

const StyledComponent = styled.div`
max-width: ${props => props.theme.width};
width: 100%;
padding-right: 15px;
padding-left: 15px;
margin-right: auto;
margin-left: auto;
`;

const Container = ({ children }) => (
  <StyledComponent>
    {children}
  </StyledComponent>
);

Container.propTypes = {
  children: pt.node.isRequired,
};

export default Container;
