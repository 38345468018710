import React, { Component } from 'react';
import pt from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';

import StyledComponent from './styles';
import {
  Layout, H1, H2, Container, Alert, Loading, Row, Column,
} from '../../components';
import ApiView from '../../components/ApiView';
import ApiForm from '../../components/forms/ApiForm';
import { createApiKey, deactivateApiKey, listApiKeys } from '../../reducers/apiKey/actions';

class ApiKeys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmited: false,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentWillMount() {
    const {
      apiKeys,
      handleList,
    } = this.props;
    if (apiKeys === null) {
      handleList();
    }
  }

  onFormSubmit(values) {
    const { handleCreate } = this.props;
    handleCreate(values);
    this.setState({
      formSubmited: true,
    });
  }

  render() {
    const {
      intl,
      apiKeySubmitErr,
      apiKeys,
      handleDeativate,
      apiKeyPreview,
    } = this.props;

    const { formSubmited } = this.state;

    return (
      <Layout>
        <StyledComponent>
          <Container>
            <H1>{intl.formatMessage({ id: 'apiKeys.title' })}</H1>
            <Row>
              <Column>
                <H2>{intl.formatMessage({ id: 'dashboard.apikeys' })}</H2>
                <ApiView data={apiKeys} handleClick={handleDeativate} />
                {formSubmited && apiKeySubmitErr ? (
                  <Alert type="error">{intl.formatMessage({ id: 'common.error' })}</Alert>
                ) : null}
                {formSubmited && !apiKeySubmitErr ? (
                  <Alert type="success">
                    {intl.formatMessage({ id: 'dashboard.apikeys.success' })}
                  </Alert>
                ) : null}
                <H2>{intl.formatMessage({ id: 'dashboard.apikeys.generate' })}</H2>
                <ApiForm apiKeyPreview={apiKeyPreview} onSubmit={this.onFormSubmit} />
              </Column>
            </Row>
          </Container>
        </StyledComponent>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  apiKeySubmitErr: state.apiKey.get('error'),
  apiKeys: state.apiKey.get('apiKeyData'),
  apiKeyPreview: state.apiKey.get('apiKeyPreview'),
});

const mapDispatchToProps = dispatch => ({
  handleCreate: (values) => {
    dispatch(createApiKey(values));
  },
  handleDeativate: (id) => {
    dispatch(deactivateApiKey(id));
  },
  handleList: () => {
    dispatch(listApiKeys());
  },
});

ApiKeys.defaultProps = {
  apiKeySubmitErr: null,
  apiKeyPreview: null,
  apiKeys: [],
};

ApiKeys.propTypes = {
  intl: intlShape.isRequired,
  apiKeySubmitErr: pt.string,
  handleCreate: pt.func.isRequired,
  handleList: pt.func.isRequired,
  handleDeativate: pt.func.isRequired,
  apiKeys: pt.instanceOf(Array),
  apiKeyPreview: pt.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ApiKeys));
